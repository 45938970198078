<template>
    <v-virtual-scroll
        class="cols-12"
        :bench="benched"
        :items="promos"
        :height="scrollerHeight"
        item-height="92"
        @scroll.native="handleScroll"
        ref="virtualScroller"
    >
        <template v-slot:default="{ item }">
            <v-list-item
                link
                three-line
                @click.stop="selected(item.IdComunicazioneGenerica)"
            >
                <div class="d-flex align-center">
                    <v-list-item-avatar rounded="0">
                        <!-- <v-img :src="getImageFile(item.ObjectIdImage)"></v-img> -->
                        <v-tooltip bottom v-if="!item.Approvata">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    :color="item.Approvata ? 'green' : 'orange'"
                                    >{{
                                        getApprovedIcon(item.Approvata)
                                    }}</v-icon
                                >
                            </template>
                            <span>{{ getTooltip(item.Approvata) }}</span>
                        </v-tooltip>

                        <v-img
                            :src="getImageFile(item.ObjectIdImage)"
                            v-else
                        ></v-img>
                    </v-list-item-avatar>
                </div>
                <v-list-item-content>
                    <v-list-item-title
                        v-text="`${item.Titolo}`"
                    ></v-list-item-title>
                    <!-- <v-list-item-subtitle
                        v-text="
                            `da ${getPromoSchedule(
                                item.InizioValidita
                            )} a ${getPromoSchedule(item.FineValidita)}`
                        "
                    >
                    </v-list-item-subtitle> -->
                    <v-list-item-subtitle>
                        <v-icon left small> mdi-calendar-start</v-icon>
                        <span class="schedule-start" :class="subtitleClass"
                            >{{ scheduleText(item).start }}
                        </span>
                        <v-spacer></v-spacer>
                        <v-icon left small>
                            mdi-calendar-end
                        </v-icon>
                        <span class="schedule-end" :class="subtitleClass"
                            >{{ scheduleText(item).end }}
                        </span>
                    </v-list-item-subtitle>
                    <!-- <v-list-item-subtitle
                        v-text="
                            `${item.CAPInsegna} ${item.ComuneInsegna} (${item.SiglaProvinciaInsegna})`
                        "
                    ></v-list-item-subtitle> -->
                </v-list-item-content>
                <v-list-item-action>
                    <v-chip-group column>
                        <v-chip outlined :color="promoState(item)">{{
                            promoMessage(item)
                        }}</v-chip>
                        <v-chip color="gray">
                            <v-avatar left class="grey lighten-1">
                                {{ item.PuntiVendita.length }}
                            </v-avatar>
                            {{
                                item.PuntiVendita.length === 1
                                    ? "Negozio"
                                    : "Negozi"
                            }}
                        </v-chip>
                        <!-- <v-menu
                            offset-y
                            :close-on-click="closeOnClick"
                            :close-on-content-click="closeOnContentClick"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    class="mx-2"
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    :retain-focus-on-click="true"
                                >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <div>ciao</div>
                        </v-menu> -->
                    </v-chip-group>
                </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
        </template>
    </v-virtual-scroll>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { promoStateMessage } from "../../mixins/utils";

import * as appActions from "../../store/actions/app";

export default {
    props: {
        promos: {
            type: Array,
            required: true
        },
        scrollerHeight: {
            type: Number,
            required: true
        }
    },

    data: () => ({
        benched: 4,
        closeOnClick: false,
        closeOnContentClick: false
    }),

    mixins: [promoStateMessage],

    computed: {
        ...mapState({
            getScrollPosition: state => state.appModule.farmPromosScrollPosition
        }),

        filePath() {
            return "/files/fileidstream";
        },

        getImageFile() {
            return id => `${this.filePath}/icoop_image/${id}`;
        },

        getApprovedIcon() {
            return approval =>
                approval ? "mdi-check" : "mdi-alert-circle-outline";
        },

        getTooltip() {
            return approval => (approval ? "Approvata" : "Da Approvare");
        },

        getPromoSchedule() {
            return schedule =>
                `${new Date(schedule).toLocaleString("it-it", {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric"
                })}`;
        },
        subtitleClass() {
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "text-caption";
                case "sm":
                    return "text-caption";
                case "md":
                    return "text-caption";
                case "lg":
                    return "text-caption";
                case "xl":
                    return "text-caption";
            }
            return "";
        },
        scrollPosition: {
            get() {
                return this.getScrollPosition;
            },
            set(value) {
                this.setListScrollPosition(value);
            }
        }
    },

    methods: {
        ...mapMutations({
            setListScrollPosition: appActions.APP_SETPROMOS_SCROLLPOSITION
        }),

        // promoState(promo) {
        //     const disabled = compareDate(promo.FineValidita) === 1;
        //     const toStart = compareDate(promo.InizioValidita) === -1;
        //     const deleted = promo.Action === 1;
        //     const suspended = promo.Sospeso === true;

        //     if (suspended || disabled && !deleted) {
        //         return "warning";
        //     }
        //     else if (deleted) {
        //         return "error";
        //     }  else if (toStart) {
        //         return "info";
        //     }
        //     return "success";
        // },

        // promoMessage(promo) {
        //     const disabled = compareDate(promo.FineValidita) === 1;
        //     const toStart = compareDate(promo.InizioValidita) === -1;
        //     const deleted = promo.Action === 1;
        //     const suspended = promo.Sospeso === true;

        //     //console.log(new Date(promo.InizioValidita).toLocaleDateString('it-IT'), dt.toLocaleDateString('it-IT'))

        //     if (suspended) {
        //         return "Sospesa";
        //     }
        //     else if (deleted) {
        //         return "Cancellata";
        //     } else if (disabled) {
        //         return "Scaduta";
        //     } else if (toStart) {
        //         return "Non Pubblicata";
        //     }
        //     return "Pubblicata";
        // },

        scheduleText(promo) {
            const from = promo.InizioPubblicazione === promo.InizioValidita ? new Date(promo.InizioValidita) : new Date(promo.InizioPubblicazione);
            const to = promo.FinePubblicazione === promo.FineValidita ? new Date(promo.FineValidita) : new Date(promo.FinePubblicazione);
            const validità = {
                start: from.toLocaleString("it-it", {
                    weekday: "short",
                    year: "numeric",
                    month: "long",
                    day: "numeric"
                }),
                end: to.toLocaleString("it-it", {
                    weekday: "short",
                    year: "numeric",
                    month: "long",
                    day: "numeric"
                })
            };

            return validità;
        },

        selected(id) {
            this.$emit("selected", id);
        },

        handleScroll(event) {
            this.scrollPosition = event.target.scrollTop;
        }
    },

    beforeMount() {
        /* console.log(this.scrollerHeight) */
        // this.resetStore();
        console.log(this.promos)
    },

    mounted() {
        this.$nextTick(() => {
            setTimeout(() => {
                if (this.$refs.virtualScroller !== undefined) {
                    this.$refs.virtualScroller.$el.scrollTop = this.scrollPosition;
                }
            }, 10);
        });
    }
};
</script>

<style scoped>
.schedule-start,
.schedule-end {
    text-transform: capitalize;
}

.v-virtual-scroll {
    padding-bottom: 128px;
}
</style>
